import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Link from "next/link";
import Image from "next/image";
/////
import benzingaImage from "@/assets/images/icons/featureIn/img-featurein-Benzinga.webp";
import businessImage from "@/assets/images/icons/featureIn/img-featurein-Business.webp";
import coingapeImage from "@/assets/images/icons/featureIn/img-featurein-Coingape.webp";
import marketwatchImage from "@/assets/images/icons/featureIn/img-featurein-Marketwatch.webp";
import morningstarImage from "@/assets/images/icons/featureIn/img-featurein-Morningstar.webp";
import yahooImage from "@/assets/images/icons/featureIn/img-featurein-Yahoo.webp";
import advfnImage from "@/assets/images/icons/featureIn/img-featurein-advfn.webp";
import apNewsImage from "@/assets/images/icons/featureIn/img-featurein-ap2.webp";
import binanceSquraeImage from "@/assets/images/icons/featureIn/img-featurein-binance-square.webp";
import coincodexImage from "@/assets/images/icons/featureIn/img-featurein-coincodex.webp";
import creativeBloqImage from "@/assets/images/icons/featureIn/img-featurein-creative_bloq.webp";
import crypto_NewsImage from "@/assets/images/icons/featureIn/img-featurein-crypto-news.svg";
import cryptoDailyImage from "@/assets/images/icons/featureIn/img-featurein-cryptodaily.svg";
import cryptoNewsImage from "@/assets/images/icons/featureIn/img-featurein-cryptonews.webp";
import digitalCameraWorldImage from "@/assets/images/icons/featureIn/img-featurein-digital-camera-world.webp";
import streetInsiderImage from "@/assets/images/icons/featureIn/img-featurein-street-insider.webp";

import bloombergIcon from "@/assets/images/icons/featureIn/bloomberg-icon-img.webp";
import bpImgIcon from "@/assets/images/icons/featureIn/bp-img-icon.webp";
import digitalJournal from "@/assets/images/icons/featureIn/digital-journal-img.webp";
import IBTImg from "@/assets/images/icons/featureIn/IBT-img.webp";
import jbtImg from "@/assets/images/icons/featureIn/jbt-img.webp";

function FeatureInSection() {
  return (
    <>
      {/* -------feature In------- */}
      <div className="center">
        <div className="featurein__header">
          <div className="hot__title h3 burst home__featureIn_center">
            <BurstPuckerImageTitles />
            Featured In<span style={{ marginLeft: "20px" }}></span>
            <BurstPuckerImageTitles />
          </div>
        </div>
        <div className="featurein__container ">
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://apnews.com/article/3e059c05986662f270da3c676250e34d"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={50}
                  height={50}
                  loading="lazy"
                  src={apNewsImage}
                  alt="AP News"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Reputable global news network; timely and comprehensive.
            </div>
          </div>
          <div className="featurein_description ">
            <div className="heading ">
              <Link
                href="https://www.benzinga.com/pressreleases/23/08/g34139987/seed-photo-expands-user-convenience-with-direct-crypto-currency-purchases"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={benzingaImage}
                  alt="Benzinga"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Stock market insights, news, and trading tools hub.
            </div>
          </div>
          <div className="featurein_description ">
            <div className="heading ">
              <Link href="#" className="featurein__item">
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={marketwatchImage}
                  alt="Marketwatch"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Real-time stocks, finance news, and market analysis.
            </div>
          </div>
          <div className="featurein_description ">
            <div className="heading ">
              <Link
                href="https://finance.yahoo.com/news/seed-photo-expands-user-convenience-115200495.html"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={90}
                  height={50}
                  loading="lazy"
                  src={yahooImage}
                  alt="Yahoo Finance"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Stock quotes, finance news, and market info source.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="#"
                // target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={morningstarImage}
                  alt="Morningstar"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Investment research, tools, and market analysis platform.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://www.bloomberg.com/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={bloombergIcon}
                  alt="binance-square"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Global business, financial news, and market data leader.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://markets.businessinsider.com/news/stocks/seed-photo-expands-user-convenience-with-direct-crypto-currency-purchases-1032596061"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={100}
                  height={50}
                  loading="lazy"
                  src={businessImage}
                  alt="Business Insider"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Breaking news, stock ratings, and financial insights.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                // href="https://coincodex.com/article/25935/the-future-of-photography-harnessing-multi-blockchain-capabilities-and-innovative-solutions/"
                href="https://coincodex.com/article/43038/seedphotos-creative-workshop-and-exclusive-gathering-in-seoul/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={coincodexImage}
                  alt="CoinCodex"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Real-time cryptocurrency prices, charts, and market info.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.creativebloq.com/features/nft-photography"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={creativeBloqImage}
                  alt="Creative Bloq"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Art, design inspiration, and creative tools resource.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                // href="https://cryptodaily.co.uk/2023/04/embracing-multi-blockchain-innovation-in-photography-industry"
                href="https://cryptodaily.co.uk/2024/05/exploring-digital-identity-in-web3-insights-from-seedphoto-at-paris-blockchain-week-2024"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={cryptoDailyImage}
                  alt="Crypto Daily"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Daily crypto news, reviews, and market updates.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://cryptonews.com/news/seedphoto-adds-ethereum-polygon-from-january-1-2023.htm"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={140}
                  height={50}
                  loading="lazy"
                  src={crypto_NewsImage}
                  alt="Crypto News"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Cryptocurrency, blockchain, and fintech news hub.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://www.digitalcameraworld.com/features/make-copyright-meaningful-protect-your-images-with-blockchains-best-solution"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={90}
                  height={50}
                  loading="lazy"
                  src={digitalCameraWorldImage}
                  alt="Digital Camera World"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Photography gear reviews, news, and magazine hub.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://cryptonews.com/news/seedphoto-adds-ethereum-polygon-from-january-1-2023.htm"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={cryptoNewsImage}
                  alt="Crypto News"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Cryptocurrency, blockchain, and fintech news hub.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://coingape.com/press-releases/seed-photo-adds-ethereum-and-polygon-from-january-1-2023/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={coingapeImage}
                  alt="CoinGape"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Cryptocurrency news, price analysis, and blockchain events.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://www.advfn.com/stock-market/stock-news/91936509/seed-photo-expands-user-convenience-with-direct-cr"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={advfnImage}
                  alt="ADVFN"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Stock prices, charts, and international market discussions.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://www.streetinsider.com/Globe+Newswire/Seed.Photo+Expands+User+Convenience+with+Direct+Crypto+Currency+Purchases/22112339.html"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={streetInsiderImage}
                  alt="Street Insider"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              Breaking news, stock ratings, and financial insights.
            </div>
          </div>

          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://theubj.com/introducing-cashclick-a-new-chapter-for-artists-and-photographers/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={110}
                  height={50}
                  loading="lazy"
                  src={jbtImg}
                  alt="Crypto News"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">Business, Finance & Entertainment News</div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://www.digitaljournal.com/pr/news/binary-news-network/cashclick-launches-new-platform-combining-1898142624.html"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={170}
                  height={80}
                  loading="lazy"
                  src={digitalJournal}
                  alt="CoinGape"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              A global media platform and content partner 
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://theblockopedia.com/seed-photo-transforming-the-future-of-photography-through-nfts-and-blockchain-innovation/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={90}
                  height={50}
                  loading="lazy"
                  src={bpImgIcon}
                  alt="ADVFN"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              the blockchain and cryptocurrency news from all over the world.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading ">
              <Link
                href="https://www.ibtimes.sg/discover-cashclick-new-way-engage-digital-creativity-75978"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={90}
                  height={50}
                  loading="lazy"
                  src={IBTImg}
                  alt="Street Insider"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body ">
              International Business Times Business News, Financial news
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeatureInSection;
